import Vue from 'vue'
import Router from 'vue-router'

import layout from '../layout'


Vue.use(Router)

function guardMyroute(to, from, next)
{
 var isAuthenticated= false;
//this is just an example. You will have to find a better or 
// centralised way to handle you localstorage data handling 
if(localStorage.getItem('user'))
  isAuthenticated = true;
 else
  isAuthenticated= false;
 if(isAuthenticated) 

 { 
  next();
 // allow to enter route
 } 
 else
 {
  next('/login'); // go to '/login';
 }
}

export default new Router({
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: '',
  routes: [
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('@/pages')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'banner',
          name: 'banner',
          component: () => import('@/pages/banner')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'amenities-banner',
          name: 'amenities-banner',
          component: () => import('@/pages/amenities-banner')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'avant-garde-banner',
          name: 'avant-garde-banner',
          component: () => import('@/pages/avant-garde-banner')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'add-avant-garde-banner',
          name: 'add-avant-garde-banner',
          component: () => import('@/pages/add-avant-garde-banner')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'edit-avant-garde-banner/:id',
          name: 'edit-avant-garde-banner',
          component: () => import('@/pages/edit-avant-garde-banner')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'add-desgin-banner',
          name: 'add-desgin-banner',
          component: () => import('@/pages/add-desgin-banner')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'edit-desgin-banner/:id',
          name: 'edit-desgin-banner',
          component: () => import('@/pages/edit-desgin-banner')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'desgin-banner',
          name: 'desgin-banner',
          component: () => import('@/pages/desgin-banner')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'add-lifestyle',
          name: 'add-lifestyle',
          component: () => import('@/pages/add-lifestyle')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'edit-lifestyle/:id',
          name: 'edit-lifestyle',
          component: () => import('@/pages/edit-lifestyle')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'lifestyle',
          name: 'lifestyle',
          component: () => import('@/pages/lifestyle')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'contactData',
          name: 'contactData',
          component: () => import('@/pages/contactData')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'contact',
          name: 'contact',
          component: () => import('@/pages/contact')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'edit-contact/:id',
          name: 'edit-contact',
          component: () => import('@/pages/edit-contact')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'desgin',
          name: 'desgin',
          component: () => import('@/pages/desgin')
        }
      ]
    },
    
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'avant-garde',
          name: 'avant-garde',
          component: () => import('@/pages/avant-garde')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'amenities',
          name: 'amenities',
          component: () => import('@/pages/amenities')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'counter',
          name: 'counter',
          component: () => import('@/pages/counter')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'location',
          name: 'location',
          component: () => import('@/pages/location')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'add-amenities-slider',
          name: 'add-amenities-slider',
          component: () => import('@/pages/add-amenities-slider')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'add-banner',
          name: 'add-banner',
          component: () => import('@/pages/add-banner')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'edit-banner/:id',
          name: 'edit-banner',
          component: () => import('@/pages/edit-banner')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'add-why-us-about',
          name: 'add-why-us-about',
          component: () => import('@/pages/add-why-us-about')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'add-locations',
          name: 'add-locations',
          component: () => import('@/pages/add-locations')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'edit-why-us-about',
          name: 'edit-why-us-about',
          component: () => import('@/pages/edit-why-us-about')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'edit-locations',
          name: 'edit-locations',
          component: () => import('@/pages/edit-locations')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'edit-amenities-slider/:id',
          name: 'edit-amenities-slider',
          component: () => import('@/pages/edit-amenities-slider')
        }
      ]
    },
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'about',
          name: 'about',
          component: () => import('@/pages/about')
        }
      ]
    },
    {
      path: '/',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/pages/login')
        },
      ]
    },
  ]
})
