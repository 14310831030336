<template>
  <section class="app-footer">
    <footer class="footer">
      <div class="container-fluid clearfix">
        <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright ©  <a href="#" target="_blank">Skyline</a> {{ new Date().getFullYear() }}.</span>
        <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"></span>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'app-footer'
}
</script>